<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <!-- <div>
        <botao-padrao
          v-if="podeExcluir"
          outlined
          class="mt-3"
          color="danger"
          @click="excluir"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div> -->

      <div class="d-flex align-center">
        <botao-padrao
          v-if="podeExcluir"
          color="secondary"
          outlined
          :disabled="!tabela.selecionados.length"
          @click="movimentarItem('cima')"
        >
          <v-icon> $arrowUp </v-icon>
        </botao-padrao>

        <botao-padrao
          v-if="podeExcluir"
          color="secondary"
          outlined
          class="mx-2"
          :disabled="!tabela.selecionados.length"
          @click="movimentarItem('baixo')"
        >
          <v-icon> $arrowDown </v-icon>
        </botao-padrao>

        <botao-padrao
          v-if="podeExcluir"
          class="my-2 ml-2"
          outlined
          color="danger"
          @click="excluir"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao
        class="mt-3"
        @click="abrirNovo"
        v-if="
          (form.tipoChecklist?.value || form.tipoChecklist) &&
          (form.tipoInstrumentoId || form.classificacaoInstrumentoId)
        "
      >
        {{ $t('modulos.checklist.botoes.add_itens') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      filtroGeral
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="form.itens"
      :mostrar-acoes="true"
      selecionar-colunas
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :filters="filters"
      :globalFilters="globalFilters"
      semPaginacao
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao text color="secondary">
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <botao-padrao
              text
              color="secondary"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </botao-padrao>
          </div>
        </dropdown-padrao>
      </template>
      <template v-slot:icone> icone </template>
    </tabela-padrao-prime-vue>
    <itens-modal
      ref="modal-itens"
      @salvar-novo="salvarNovoitems"
      :formChecklist="form"
      :valorTipoChecklist="valorTipoChecklist"
    />
  </div>
</template>
<script>
import ItensModal from './modais/ItensModal';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
  components: {
    ItensModal,
  },
  props: {
    form: {},
    valorTipoChecklist: { type: String, default: '' },
  },
  data() {
    return {
      edicao: null,
      globalFilters: [
        'sequencia',
        'descricao',
        'acaoNaoOk.nome',
        'servicos.length',
        'pecas.length',
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sequencia: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        descricao: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        acaoNaoOk: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        servicos: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        pecas: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'sequencia',
            text: this.$t('modulos.checklist.itens.tabela.sequencia'),
          },
          {
            value: 'descricao',
            text: this.$t('modulos.checklist.itens.tabela.descricao'),
          },
          {
            value: 'acaoNaoOk',
            text: this.$t('modulos.checklist.itens.tabela.acao_nao_ok'),
          },
          {
            value: 'servicos.length',
            text: this.$t('modulos.checklist.itens.tabela.servicos'),
          },
          {
            value: 'pecas.length',
            text: this.$t('modulos.checklist.itens.tabela.pecas'),
          },
        ],
      },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  methods: {
    abrirNovo: function () {
      this.$refs['modal-itens'].abrirModal();
    },
    salvarNovoitems: function (form) {
      this.form.itens.push(form);
      form.ordem = this.form.itens?.length;
      this.refazerSequenciaItens();
    },
    abrirEditar: function (item) {
      let form = item;
      this.$refs['modal-itens'].abrirModal(form);
    },
    excluir: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.itens.splice(this.form.itens.indexOf(element), 1);
        });
        this.tabela.selecionados = [];
        this.refazerSequenciaItens();
      });
    },
    refazerSequenciaItens() {
      let sequencia = 1;
      this.form.itens.map((item) => {
        item.sequencia = sequencia++;
      });
    },
    movimentarItem: function (direcao) {
      const incremento = direcao === 'cima' ? -1 : 1;
      let itensSelecionados = [...this.tabela.selecionados];
      let itensSelecionadosComIndex = itensSelecionados.map((item) => {
        return {
          ...item,
          index: this.form.itens.indexOf(item),
        };
      });

      itensSelecionadosComIndex.sort((a, b) =>
        incremento === -1 ? a.index - b.index : b.index - a.index
      );

      itensSelecionadosComIndex.forEach((item) => {
        const novaLista = [...this.form.itens];
        const novoIndex = item.index + incremento;

        if (novoIndex < 0 || novoIndex >= novaLista.length) {
          return;
        }
        const itemListaAntes = novaLista[item.index + incremento];

        novaLista[item.index + incremento] = novaLista[item.index];
        novaLista[item.index] = itemListaAntes;
        this.form.itens = [...novaLista];
      });

      this.refazerSequenciaItens();
    },
  },
};
</script>
