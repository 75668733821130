<template>
  <modal-padrao
    ref="modal-itens"
    max-width="80%"
    :titulo="$t('modulos.checklist.itens.modal.itens')"
    :ok-desabilitado="!valido"
    :titulo-ok="$t('geral.botoes.confirmar')"
    @ok="confirmacaoSalvar"
  >
    <v-form
      ref="item"
      class="row"
    >
      <input-text
        v-model="item.descricao"
        class="col-12 col-md-6 pb-0"
        :label="$t('modulos.checklist.itens.modal.filtro.descricao')"
        :max="150"
        obrigatorio
        trim
      />
      <input-text
        v-model="item.acaoNaoOk"
        class="col-12 col-md-6 pb-0"
        :label="$t('modulos.checklist.itens.modal.filtro.acao_nao_ok')"
        :max="150"
        obrigatorio
        trim
      />
      <span
        style="font-size: 16px; font-weight: 400"
        class="mt-4 ml-3"
      >
        {{
          valorTipoChecklist == 'AnaliseTecnica'
            ? $t('modulos.checklist.titulos.servicos_para_analise_tecnica')
            : ''
        }}
      </span>
      <accordion-padrao
        v-if="valorTipoChecklist == 'AnaliseTecnica'"
        :titulo="$t('modulos.checklist.itens.modal.tabelaServico.titulo')"
        class="col-12 col-md-12 pt-0"
        alterar-cor
        obrigatorio
      >
        <tabela-padrao
          v-model="item.servicos"
          :dados="tabelaServicos.dados"
          :colunas="tabelaServicos.colunas"
          :por-pagina="tabelaServicos.porPagina"
          :quantidade-paginas="tabelaServicos.quantidadePaginas"
          sem-acoes
          :selecionar-apenas-um="false"
          @paginar="listar"
        />
      </accordion-padrao>
      <accordion-padrao
        v-if="valorTipoChecklist == 'AnaliseTecnica'"
        :titulo="$t('modulos.checklist.itens.modal.tabelaPecas.titulo')"
        class="col-12 col-md-12"
        alterar-cor
        obrigatorio
      >
        <tabela-padrao
          v-model="item.pecas"
          :dados="tabelaPecas.dados"
          :colunas="tabelaPecas.colunas"
          :por-pagina="tabelaPecas.porPagina"
          :quantidade-paginas="tabelaPecas.quantidadePaginas"
          sem-acoes
          :selecionar-apenas-um="false"
          @paginar="listar"
        />
      </accordion-padrao>
    </v-form>
  </modal-padrao>
</template>
<script>
import ServicoService from '@common/services/cadastros/ServicoService';
import ItemService from '@common/services/cadastros/ItemService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    valorTipoChecklist: { type: String, default: '' },
    formChecklist: {},
  },
  data() {
    return {
      edicao: false,
      valido: null,
      sequencia: 1,
      form: {},
      itemReferencia: {},
      tabelaServicos: {
        dados: [],
        selecionados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t(
              'modulos.checklist.itens.modal.tabelaServico.colunas.codigo'
            ),
            sortable: true,
          },
          {
            value: 'nome',
            text: this.$t(
              'modulos.checklist.itens.modal.tabelaServico.colunas.nome'
            ),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      tabelaPecas: {
        dados: [],
        selecionados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t(
              'modulos.checklist.itens.modal.tabelaPecas.colunas.codigo'
            ),
            sortable: true,
          },
          {
            value: 'descricao',
            text: this.$t(
              'modulos.checklist.itens.modal.tabelaPecas.colunas.descricao'
            ),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      item: {
        descricao: null,
        acaoNaoOk: null,
        pecas: [],
        servicos: [],
      },
    };
  },
  watch: {
    item: {
      deep: true,
      handler() {
        if (this.$refs.item) this.valido = this.$refs.item.validate();
      },
    },
  },
  mounted() {
    this.iniciarForm();
  },
  methods: {
    iniciarForm: function () {
      this.item = {
        descricao: null,
        acaoNaoOk: null,
        pecas: [],
        servicos: [],
      };
    },
    abrirModal: async function (form) {
      await this.listar();
      this.iniciarForm();
      this.edicao = false;
      if (form) {
        this.valido = true;
        this.edicao = true;
        this.itemReferencia = form;
        this.item = _.cloneDeep(form);
        if (this.item.pecas.length && this.tabelaPecas.dados.length) {
          var pecas = JSON.parse(JSON.stringify(this.item.pecas));
          this.item.pecas = [];
          this.selecionaItensEdicao(this.tabelaPecas.dados, pecas, 'pecas');
        }
        if (this.item.servicos.length && this.tabelaServicos.dados.length) {
          var servicos = JSON.parse(JSON.stringify(this.item.servicos));
          this.item.servicos = [];
          this.selecionaItensEdicao(
            this.tabelaServicos.dados,
            servicos,
            'servicos'
          );
        }
      }

      this.$refs['modal-itens'].abrirModal();
      if (this.$refs.item) this.valido = this.$refs.item.resetValidation();
    },
    selecionaItensEdicao(lista, itens, tipo) {
      lista.forEach((itemLista) => {
        itens.forEach((item) => {
          if (
            (itemLista.id == item || itemLista.id == item.id) &&
            tipo == 'pecas'
          ) {
            this.item.pecas.push(itemLista);
          }
          if (
            (itemLista.id == item || itemLista.id == item.id) &&
            tipo == 'servicos'
          ) {
            this.item.servicos.push(itemLista);
          }
        });
      });
    },
    salvar: function () {
      if (!this.$refs.item.validate()) return;

      this.item.id = this.sequencia++;

      if (!this.edicao) this.$emit('salvar-novo', this.item);
      if (this.edicao) {
        Object.entries(this.item).forEach((value) => {
          this.itemReferencia[value[0]] = value[1];
        });
      }
      this.$refs['modal-itens'].fecharModal();
    },
    confirmacaoSalvar: function () {
      if (this.edicao) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    listar: async function (
      paginaAtual = 1,
      porPagina = 10,
      papelFornecedor = 1
    ) {
      let parametrosServicos = {
        page: paginaAtual,
        amountPerPage: porPagina,
        papelFornecedor: papelFornecedor,
        tipoInstrumentoId: this.formChecklist.tipoInstrumentoId?.value,
        classificacaoInstrumentoId:
          this.formChecklist.classificacaoInstrumentoId?.value,
        tipoServico: 2,
      };
      if (!this.formChecklist.tipoInstrumentoId?.value) {
        delete parametrosServicos.tipoInstrumentoId;
      }
      if (!this.formChecklist.classificacaoInstrumentoId?.value) {
        delete parametrosServicos.classificacaoInstrumentoId;
      }
      let parametrosPecas = {
        page: paginaAtual,
        amountPerPage: porPagina,
        papelFornecedor: papelFornecedor,
        tipoInstrumentoId: this.formChecklist.tipoInstrumentoId?.value,
        classificacaoInstrumentoId:
          this.formChecklist.classificacaoInstrumentoId?.value,
      };
      if (!this.formChecklist.tipoInstrumentoId?.value) {
        delete parametrosPecas.tipoInstrumentoId;
      }
      if (!this.formChecklist.classificacaoInstrumentoId?.value) {
        delete parametrosPecas.classificacaoInstrumentoId;
      }
      if (
        this.formChecklist?.tipoChecklist?.value == 'AnaliseTecnica' ||
        this.formChecklist.tipoChecklist == 'AnaliseTecnica'
      ) {
        this.$store.dispatch('Layout/iniciarCarregamento');
        await ServicoService.listar(parametrosServicos)
          .then((res) => {
            this.tabelaServicos.dados = res.data.items;

            this.tabelaServicos.quantidadeItens = res.data.totalItems;
            this.tabelaServicos.quantidadePaginas = res.data.totalPages;
            this.tabelaServicos.paginaAtual = paginaAtual;
            this.tabelaServicos.porPagina = porPagina;
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });

        this.$store.dispatch('Layout/iniciarCarregamento');
        await ItemService.buscarPecas(parametrosPecas, '')
          .then((res) => {
            this.tabelaPecas.dados = res.data.items;

            this.tabelaPecas.quantidadeItens = res.data.totalItems;
            this.tabelaPecas.quantidadePaginas = res.data.totalPages;
            this.tabelaPecas.paginaAtual = paginaAtual;
            this.tabelaPecas.porPagina = porPagina;
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      }
    },
  },
};
</script>
