<template>
  <div>
    <v-form ref="form">
      <div class="row">
        <input-select v-model="form.tipoChecklist" class="col-12 col-md-4"
          :label="$t('modulos.checklist.formulario.tipo_checklist')" :options="opcoes.tipoChecklist" retornar-objeto
          obrigatorio />
        <input-classificacao-instrumento v-model="form.classificacaoInstrumentoId" class="col-12 col-md-4"
          :label="$t('modulos.checklist.formulario.classificacao_instrumento')" :multiplos="false"
          :loading="loadingClassificacaoInstrumento" :tool-tip-informacao="$t('modulos.checklist.toolTip.classificacao_instrumento')
          " @input="limparTiposInstrumentos" />
        <input-tipo-instrumento v-model="form.tipoInstrumentoId" class="col-12 col-md-4"
          :label="$t('modulos.checklist.formulario.tipo_instrumento')" :tool-tip-informacao="$t('modulos.checklist.toolTip.tipo_instrumento')
          " :multiplos="false" :loading="loadingTipoInstrumento" @input="limparClassificacao" />
      </div>
    </v-form>
  </div>
</template>
<script>
import {
  InputTipoInstrumento,
  InputClassificacaoInstrumento,
} from '@components/inputs';
import TipoInstrumentoService from '@common/services/cadastros/TipoInstrumentoService';
import ClassificacaoInstrumentoService from '@common/services/cadastros/ClassificacaoInstrumentoService';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
export default {
  components: { InputTipoInstrumento, InputClassificacaoInstrumento },
  props: {
    form: {},
    erros: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      opcoes: {
        tipoChecklist: [],
      },
      loadingClassificacaoInstrumento: false,
      loadingTipoInstrumento: false,
      validaValorAnteriorTipoChecklist: false,
    };
  },
  watch: {
    'form.tipoInstrumentoId': {
      handler() {
        if (this.form.id && !this.form.tipoInstrumentoId?.text)
          this.listarTipoInstrumento();
      },
      deep: true,
    },
    'form.classificacaoInstrumentoId': {
      handler() {
        if (this.form.id && !this.form.classificacaoInstrumentoId?.text)
          this.listarClassificacaoInstrumento();
      },
      deep: true,
    },
    'form.tipoChecklist'(valor, valorAnterior) {
      if (!valorAnterior) {
        return;
      }
      if (this.validaValorAnteriorTipoChecklist) {
        this.validaValorAnteriorTipoChecklist = false;
        return;
      }
      if (this.form.tipoChecklist && this.form.itens.length) {
        this.confirmarAlteracaoTipoChecklist()
          .then(() => {
            this.form.itens = [];
          })
          .catch(() => {
            this.form.tipoChecklist = valorAnterior;
            this.validaValorAnteriorTipoChecklist = true;
            return;
          });
      }
    },
  },
  mounted() {
    this.buscarTipoChecklist();
  },
  methods: {
    limparClassificacao() {
      if (this.form.tipoInstrumentoId != null)
        this.form.classificacaoInstrumentoId = null;
    },
    limparTiposInstrumentos() {
      if (this.form.classificacaoInstrumentoId != null)
        this.form.tipoInstrumentoId = null;
    },
    listarTipoInstrumento() {
      this.loadingTipoInstrumento = true;
      TipoInstrumentoService.buscar(this.form.tipoInstrumentoId)
        .then((res) => {
          let tipoInstrumento = res.data;
          if (tipoInstrumento.id === this.form.tipoInstrumentoId)
            this.form.tipoInstrumentoId = {
              text: tipoInstrumento.nome,
              value: tipoInstrumento.id,
            };
        })
        .finally(() => {
          this.loadingTipoInstrumento = false;
        });
    },
    listarClassificacaoInstrumento() {
      let parametros = {
        page: 1,
        amountPerPage: 999999999,
        ...this.filtroAplicado,
      };
      this.loadingClassificacaoInstrumento = true;
      ClassificacaoInstrumentoService.listar(parametros)
        .then((res) => {
          let classificacaoInstrumentoLista = res.data;
          classificacaoInstrumentoLista.find((item) => {
            if (item.id === this.form.classificacaoInstrumentoId) {
              return (this.form.classificacaoInstrumentoId = {
                text: item.descricao,
                value: item.id,
              });
            }
          });
        })
        .finally(() => {
          this.loadingClassificacaoInstrumento = false;
        });
    },
    buscarTipoChecklist: function () {
      EnumeradorService.buscar('EnumTipoChecklist').then((res) => {
        this.opcoes.tipoChecklist = res;
      });
    },
  },
};
</script>
