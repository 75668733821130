import _ from 'lodash';
export class ChecklistModel {
  constructor({
    id,
    tipoChecklist,
    tipoInstrumentoId,
    classificacaoInstrumentoId,
    itens,
  }) {
    this.id = id;
    this.tipoChecklist = tipoChecklist;
    (this.tipoInstrumentoId = tipoInstrumentoId),
      (this.classificacaoInstrumentoId = classificacaoInstrumentoId);
    this.itens = [];
    if (this.id) this.itens = itens;
  }

  get request() {
    let retorno = _.cloneDeep(this);
    if (typeof this.tipoChecklist !== 'number')
      retorno.tipoChecklist = retorno.tipoChecklist.value
        ? retorno.tipoChecklist.value
        : retorno.tipoChecklist;
    retorno.tipoInstrumentoId = retorno.tipoInstrumentoId?.value;
    retorno.classificacaoInstrumentoId =
      retorno.classificacaoInstrumentoId?.value;

    retorno.itens = this.itens.map((item) => {
      return {
        ...item,
        id: () => {
          if (item.id > 35) return '';
          return item.id;
        },
        servicos: item.servicos.map((item) => {
          if (item.id !== undefined) return item.id;
          return item;
        }),
        pecas: item.pecas.map((item) => {
          if (item.id !== undefined) return item.id;
          return item;
        }),
      };
    });
    return retorno;
  }
}
