<template>
  <div>
    <div class="d-flex mb-4">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <accordion-padrao
      :titulo="$t('modulos.checklist.formulario.dados_checklist')"
      class="col-12 col-md-12 pt-0"
      alterar-cor
      cor-azul-texto
    >
      <dados-checklist
        ref="dados-checklist"
        :form="form"
      />
    </accordion-padrao>

    <accordion-padrao
      :titulo="$t('modulos.checklist.formulario.itens')"
      class="col-12 col-md-12 pt-0"
      alterar-cor
      cor-azul-texto
    >
      <itens
        ref="item"
        :form="form"
        :valor-tipo-checklist="valorTipoChecklist"
      />
    </accordion-padrao>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        :disabled="!valido"
        :tooltip="tooltipBotaoSalvar"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import DadosChecklist from './components/DadosChecklist';
import Itens from './components/Itens';
import ChecklistService from '@common/services/cadastros/ChecklistService';
import { ChecklistModel } from '@common/models/cadastros/ChecklistModel';

export default {
  components: {
    DadosChecklist,
    Itens,
  },
  props: ['id'],
  data() {
    return {
      valido: false,
      valorTipoChecklist: null,
      indiceAba: 0,
      form: new ChecklistModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.checklist.titulos.editar');
      return this.$t('modulos.checklist.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.checklist.validacoes.formulario_invalido');
    },
  },
  watch: {
    form: {
      handler() {
        if (this.form.tipoChecklist) {
          this.valorTipoChecklist = this.form.tipoChecklist.value
            ? this.form.tipoChecklist.value
            : this.form.tipoChecklist;
        }
        if (
          this.$refs['dados-checklist'] &&
          (this.form.tipoInstrumentoId || this.form.classificacaoInstrumentoId)
        ) {
          this.valido = this.$refs['dados-checklist'].$refs.form.validate();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this,'Checklist', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'Checklist', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ChecklistService.buscar(this.id)
        .then((res) => {
          this.form = new ChecklistModel(res.data);
          this.valido = true;
          this.valorTipoChecklist = res.data.tipoChecklist;

          if (this.$route.name == 'checklist-duplicar') {
            this.form.id = null;
            this.form.classificacaoInstrumentoId = null;
            this.form.tipoInstrumentoId = null;
            this.form.itens.map((item) => {
              item.pecas = [];
              item.servicos = [];
            });
          }
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.checklist.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (
        !this.form.tipoInstrumentoId &&
        !this.form.classificacaoInstrumentoId
      ) {
        this.toastAlerta(
          this.$t(
            `modulos.checklist.erros.deve_ter_tipo_instrumento_ou_classificacao_instrumento`
          )
        );
        return;
      }
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ChecklistService.salvar(this.form)
        .then(() => {
          this.$router.push({ name: 'checklist' });
          this.toastSucesso(this.$t(`modulos.checklist.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'checklist' });
      });
    },
  },
};
</script>
